<template>
    <div>
        <div class="onboardingMainBox" v-if="activeStep != 6">
            <div class="onboardingAsideBlock">
                <div class="onboardingAsideFlexBox onboardingSideFlexBox">
                    <div class="onboardingAsideTxtBox">
                        <h1>Create your Free <span>Online Store</span> on Yeetcommerce.com</h1>
                    </div>
                    <div class="onBoardingCoverImage">
                        <img class="onboardingAsideCoverImg" src="@/assets/images/yeet/yeet_dashboard.png" alt="">
                        <div class="boardingStatImg1">
                            <img src="@/assets/images/ordersValueImage.png" alt="">
                        </div>
                        <div class="boardingStatImg2">
                            <img src="@/assets/images/listingImage.png" alt="">
                        </div>
                        <div class="boardingStatImg3">
                            <img src="@/assets/images/visitorsImage.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="whatsappIcon" v-if="this.countryCheckLoader">
                <vue-skeleton-loader
                    type="circle"
                    :width="60"
                    :height="60"
                    animation="fade"
                />
            </div>
            <div class="whatsappIcon" v-else>
                <a :href="countryIsUSA ? `https://wa.me/13142978112` : `https://wa.me/923164000277`" target="_blank">
                    <img src="@/assets/images/whatsapp_logo.png" alt="whatsapp_icon">
                </a>
            </div>

            <!-- step 1 -->      
            <div class="onboardingRightsideBlock stepOne" v-if="activeStep == 1">
                <ValidationObserver ref="stepOneObserver" >
                    <form @submit.prevent="validate('One')">
                        <div class="onboardingContentBox">
                            <div class="onboardingLogo">
                                <a>
                                    <img src="@/assets/images/yeet/yeet_logo.png" alt="">
                                </a>
                            </div>
                            <div class="stepsBarBox">
                                <div class="stepsItem activeStep"></div>
                                <div class="stepsItem"></div>
                                <div class="stepsItem"></div>
                                <div class="stepsItem"></div>
                                <div class="stepsItem"></div>
                                <div class="stepsItem"></div>
                            </div>
                            <div class="onboardingForm">
                                <h2>Let's Begin: Create Your Store by Signing Up with Your Information!</h2>
                                <p>We’ll help you get set up based on your business needs.</p>
                                <!-- <div class="startedFormBox">
                                    <ValidationProvider name="contact no." ref="contactProvider" :rules="countryIsSelected ? 'required': '' " v-slot="{ errors }">
                                        <label for="contactNo">Your Contact No. <span>*</span></label>
                                        <vue-tel-input 
                                            v-model="store.mobile_no"
                                            ref="numberInput"
                                            mode="international"
                                            :onlyCountries="allowedCountries"
                                            :inputOptions="options"
                                            :dropdownOptions="dropDownOptions"
                                            :validCharactersOnly=true
                                            @input="numberInputHandle"
                                            @validate="validateContactNo"
                                            @country-changed="countryChangeHandle"
                                            @open="dropDownOpenHandle"
                                            @close="dropDownCloseHandle"
                                        >
                                        </vue-tel-input>
                                        <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                                        <span class="text-danger" v-else-if="!countryIsSelected">Select a country from the dropdown menu or add the country code before the contact no.</span>
                                        <span class="text-danger" v-else-if="!mobileNoIsValid">Enter a valid contact no.</span>
                                    </ValidationProvider>
                                </div> -->
                                <!-- <div class="inboardingSubmitBtn">
                                    <div class="loginBtn">
                                        <p>Receive message via</p>
                                    </div>
                                    <div class="submitBtns">
                                        <el-radio-group v-model="store.otp_platform" size="large">
                                            <el-radio-button label="whatsapp" />
                                            <el-radio-button label="text message" />
                                        </el-radio-group>
                                    </div>
                                </div> -->
                                <!-- <div class="inboardingSubmitBtn">
                                    <div class="loginBtn">
                                        <p>Already have an account?</p>
                                        <router-link to="/login">Login</router-link>
                                    </div>
                                    <div class="submitBtns">
                                        <loaderBtn v-if="loader"/>
                                        <button type="submit" v-else>Next</button>
                                    </div>
                                </div> -->
                                <div class="startedFormBox">
                                    <ValidationProvider name="email" :rules="{required: true, max:250,email: true }" v-slot="{ errors }">
                                        <label for="userEmail">Email Address<span>*</span></label>
                                        <input type="text" name="userEmail" id="userEmail" placeholder="johndoe@gmail.com" v-model="store.email">
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="formSubmitBtn">
                                    <loaderBtn v-if="loader"/>
                                    <button type="submit" v-else>Next</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="googleCustomBtn">
                        <button @click.prevent="onboardWithGoogle" :disabled="socialLoginChecking">
                            <svg enable-background="new 0 0 128 128" id="Social_Icons" version="1.1" viewBox="0 0 128 128" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="_x31__stroke">
                                    <g id="Google">
                                        <rect clip-rule="evenodd" fill="none" fill-rule="evenodd" height="128" width="128"/>
                                        <path clip-rule="evenodd" d="M27.585,64c0-4.157,0.69-8.143,1.923-11.881L7.938,35.648    C3.734,44.183,1.366,53.801,1.366,64c0,10.191,2.366,19.802,6.563,28.332l21.558-16.503C28.266,72.108,27.585,68.137,27.585,64" fill="#FBBC05" fill-rule="evenodd"/>
                                        <path clip-rule="evenodd" d="M65.457,26.182c9.031,0,17.188,3.2,23.597,8.436L107.698,16    C96.337,6.109,81.771,0,65.457,0C40.129,0,18.361,14.484,7.938,35.648l21.569,16.471C34.477,37.033,48.644,26.182,65.457,26.182" fill="#EA4335" fill-rule="evenodd"/>
                                        <path clip-rule="evenodd" d="M65.457,101.818c-16.812,0-30.979-10.851-35.949-25.937    L7.938,92.349C18.361,113.516,40.129,128,65.457,128c15.632,0,30.557-5.551,41.758-15.951L86.741,96.221    C80.964,99.86,73.689,101.818,65.457,101.818" fill="#34A853" fill-rule="evenodd"/>
                                        <path clip-rule="evenodd" d="M126.634,64c0-3.782-0.583-7.855-1.457-11.636H65.457v24.727    h34.376c-1.719,8.431-6.397,14.912-13.092,19.13l20.474,15.828C118.981,101.129,126.634,84.861,126.634,64" fill="#4285F4" fill-rule="evenodd"/>
                                    </g>
                                </g>
                            </svg>
                            Continue With Google
                        </button>
                    </div>
                    <div class="googleCustomBtn" v-if="isDev()">
                        <button @click.prevent="onboardWithOutlookInitiate" :disabled="socialLoginChecking">
                            <svg height="100px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="100px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="_x37_4-outlook">
                                    <g>
                                        <g>
                                            <path d="M479.689,121.431H270.912c-8.23,0-14.91,6.705-14.91,14.958c0,8.251,6.68,14.95,14.91,14.95     h165.322l-91.621,71.447l-65.709-41.901L262.92,206.13l74.559,47.548c2.418,1.56,5.221,2.332,7.996,2.332     c3.221,0,6.473-1.045,9.16-3.14l110.143-85.924v193.736H270.912c-8.23,0-14.91,6.699-14.91,14.946     c0,8.257,6.68,14.959,14.91,14.959h208.777c8.234,0,14.914-6.702,14.914-14.959v-239.24     C494.604,128.136,487.924,121.431,479.689,121.431L479.689,121.431z M479.689,121.431" style="fill:#1B80E4;"/>
                                            <path d="M280.428,35.153c-3.404-2.841-7.967-4.069-12.26-3.168L29.566,76.84     c-7.068,1.322-12.169,7.481-12.169,14.687v328.966c0,7.177,5.101,13.364,12.169,14.688l238.602,44.856     c0.893,0.177,1.818,0.271,2.744,0.271c3.459,0,6.828-1.199,9.516-3.445c3.432-2.836,5.393-7.083,5.393-11.512V46.671     C285.82,42.213,283.859,37.995,280.428,35.153L280.428,35.153z M280.428,35.153" style="fill:#2299F8;"/>
                                            <path d="M151.609,360.683c-41.1,0-74.563-40.253-74.563-89.72c0-49.462,33.464-89.719,74.563-89.719     c41.1,0,74.565,40.257,74.565,89.719C226.174,320.43,192.709,360.683,151.609,360.683L151.609,360.683z M151.609,211.153     c-24.665,0-44.736,26.824-44.736,59.81c0,32.988,20.071,59.813,44.736,59.813c24.666,0,44.743-26.824,44.743-59.813     C196.352,237.978,176.275,211.153,151.609,211.153L151.609,211.153z M151.609,211.153" style="fill:#FCFCFC;"/>
                                        </g>
                                    </g>
                                </g>
                                <g id="Layer_1"/>
                            </svg>
                            Continue With Outlook
                        </button>
                    </div>
                    <div class="formBottomTxt">
                        <h4>Already have an account? <button @click.prevent="$router.push('login')">Login</button></h4>
                    </div>
                </ValidationObserver>
            </div>
            <!-- step 2 -->
            <div class="onboardingRightsideBlock" v-if="activeStep == 2">
                <div class="onboardingContentBox">
                    <div class="onboardingLogo">
                        <a>
                            <img src="@/assets/images/yeet/yeet_logo.png" alt="">
                        </a>
                    </div>
                    <div class="stepsBarBox">
                        <div class="stepsItem"></div>
                        <div class="stepsItem activeStep"></div>
                        <div class="stepsItem"></div>
                        <div class="stepsItem"></div>
                        <div class="stepsItem"></div>
                        <div class="stepsItem"></div>
                    </div>
                    <div class="onboardingForm">
                        <h2>Verify your Email Address</h2>
                        <p>Thank you for registration. Enter the OTP sent to your email address.</p>
                        <ValidationObserver ref="stepTwoObserver" v-slot="{ handleSubmit }">
                            <ValidationProvider name="otp" rules="required" v-slot="{ errors }">
                                <div class="verificationFlexBox">
                                    <OtpInput
                                        ref="otpInput"
                                        input-classes="otp-input"
                                        separator="-"
                                        :num-inputs="6"
                                        :should-auto-focus="true"
                                        :is-input-num="true"
                                        v-model="store.otp"
                                        @on-change="handleEmailOtpChange"
                                        @on-complete="handleEmailOtpComplete"
                                    />
                                </div>
                                <div class="sndCode">
                                    <span class="text-danger" >{{ errors[0] }}</span>
                                    <p v-if="devOtp != ''">OTP: {{ devOtp }}</p>
                                    <VueCountdown ref="countdown" :time="300000" @end="isTimeOut = true" v-if="store.verified_otp == '' && !isTimeOut">
                                        <template slot-scope="props">{{ props.minutes }} minute(s), {{ props.seconds }} seconds.</template>
                                    </VueCountdown>
                                    <p v-if="isTimeOut">Didn't get the code?</p>
                                    <a href="#." @click.prevent="sendEmailVerificationOtp" v-if="isTimeOut">Resend Code</a>
                                </div>
                            </ValidationProvider>
                            <div class="inboardingSubmitBtn" style="justify-content: space-between;">
                                <button class="backStepBtn" @click.prevent="changeStep('decr')">Back</button>
                                <loaderBtn v-if="loader"/>
                                <button type="submit" @click.prevent="handleSubmit(verifyEmailVerificationOtp)" v-else>Next</button>
                            </div>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
            <!-- step 3 -->
            <div class="onboardingRightsideBlock" v-if="activeStep == 3">
                <ValidationObserver ref="stepThreeObserver">
                    <form @submit.prevent="validate('Three')">
                        <div class="onboardingContentBox">
                            <div class="onboardingLogo">
                                <a>
                                    <img src="@/assets/images/yeet/yeet_logo.png" alt="">
                                </a>
                            </div>
                            <div class="stepsBarBox">
                                <div class="stepsItem"></div>
                                <div class="stepsItem"></div>
                                <div class="stepsItem activeStep"></div>
                                <div class="stepsItem"></div>
                                <div class="stepsItem"></div>
                                <div class="stepsItem"></div>
                            </div>
                            <div class="onboardingForm">
                                <h2>Let's get some basic information about you</h2>
                                <p>We’ll need this information to link your customers with you.</p>
                                <div class="startedFormBox">
                                    <ValidationProvider name="first name" rules="required|max:50" v-slot="{ errors }">
                                        <label for="firstName">First Name<span>*</span></label>
                                        <input type="text" name="firstName" id="firstName" placeholder="John" v-model="store.first_name">
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="startedFormBox">
                                    <ValidationProvider name="last name" rules="required|max:50" v-slot="{ errors }">
                                        <label for="lastName">Last Name<span>*</span></label>
                                        <input type="text" name="lastName" id="lastName" placeholder="Doe" v-model="store.last_name">
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="startedFormBox">
                                    <ValidationProvider name="contact no." ref="contactProvider" :rules=" countryIsSelected ? 'required' : '' " v-slot="{ errors }">
                                        <label for="contactNo">Contact No.<span>*</span></label>
                                        <vue-tel-input 
                                            v-model="store.mobile_no"
                                            ref="numberInput"
                                            mode="international"
                                            :onlyCountries="allowedCountries"
                                            :inputOptions="options"
                                            :dropdownOptions="dropDownOptions"
                                            :validCharactersOnly=true
                                            @input="numberInputHandle"
                                            @validate="validateContactNo"
                                            @country-changed="countryChangeHandle"
                                            @open="dropDownOpenHandle"
                                            @close="dropDownCloseHandle"
                                        >
                                        </vue-tel-input>
                                        <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                                        <span class="text-danger" v-else-if="!countryIsSelected && contactFieldTouched">Select a country from the dropdown menu or add the country code before the contact no.</span>
                                        <span class="text-danger" v-else-if="!mobileNoIsValid && contactFieldTouched">Enter a valid contact no.</span>
                                    </ValidationProvider>
                                </div>
                                <div class="startedFormBox passwordField">
                                    <ValidationProvider name="password" rules="required|min:8|max:250" v-slot="{ errors }">
                                        <a href="#." class="genPasswordBtn" @click.prevent="generatePassword()">Generate Password</a>
                                        <label for="userPassword">Password<span>*</span></label>
                                        <input 
                                            :type="store.passwordType ? 'text' : 'password'" 
                                            name="userPassword" 
                                            id="userPassword" 
                                            placeholder="***********" 
                                            v-model="store.password" 
                                            autocomplete
                                            ref="passwordInput"
                                        >
                                        <span class="text-danger">{{ errors[0] }}</span>
                                        <button class="PasswordEyeBtn" type="button" @click="store.passwordType = !store.passwordType">
                                            <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="store.passwordType">
                                                <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                                                <g id="eyeOpen" transform="translate(-355 -428)">
                                                    <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                                        <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                                                    </g>
                                                    <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                                                </g>
                                            </svg>
                                        </button>
                                    </ValidationProvider>
                                </div>
                                <div class="inboardingSubmitBtn" style="justify-content: space-between;">
                                    <button class="backStepBtn" type="button" @click.prevent="changeStep('decr')">Back</button>
                                    <div class="submitBtns">
                                        <loaderBtn v-if="loader"/>
                                        <button type="submit" v-else>Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
            <!-- step 4 -->
            <div class="onboardingRightsideBlock stepThreeBox" v-if="activeStep == 4">
                <div class="onboardingContentBox">
                    <div class="onboardingLogo">
                        <a>
                            <img src="@/assets/images/yeet/yeet_logo.png" alt="">
                        </a>
                    </div>
                    <div class="stepsBarBox stepThreeBlock">
                        <div class="stepsItem"></div>
                        <div class="stepsItem"></div>
                        <div class="stepsItem"></div>
                        <div class="stepsItem activeStep"></div>
                        <div class="stepsItem"></div>
                        <div class="stepsItem"></div>
                    </div>
                    <ValidationObserver ref="onboardingObserver">
                        <div class="categorySelectBox">
                            <div class="uploadLogoBox">
                                <div class="placeholderImgSide" id="store-image">
                                    <img :src="store_settings.logo ? store_settings.logo_src : '/images/logo-placeholder.jpg'" alt="">
                                </div>
                                <input class="chooseFileUploader" type="file" accept="image/png,image/jpg,image/jpeg,image/webp" @change.prevent="storeImageHandle">
                                <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.346 21.1">
                                        <path id="camera" d="M10.956,8.521c1.006,0,3.083.357,3.8,1.071a3.644,3.644,0,0,1,0,5.161,3.644,3.644,0,0,1-5.161,0,3.644,3.644,0,0,1,0-5.161C10.307,8.878,9.95,8.521,10.956,8.521ZM21.1,3.246a3.129,3.129,0,0,1,2.3.951,3.124,3.124,0,0,1,.951,2.3V17.854A3.246,3.246,0,0,1,21.1,21.1H3.246a3.129,3.129,0,0,1-2.3-.951A3.124,3.124,0,0,1,0,17.854V6.492A3.129,3.129,0,0,1,.951,4.2a3.124,3.124,0,0,1,2.3-.951h2.84l.647-1.725A2.351,2.351,0,0,1,7.614.45,2.264,2.264,0,0,1,8.927,0h6.492a2.258,2.258,0,0,1,1.313.45,2.355,2.355,0,0,1,.881,1.071l.647,1.725ZM12.173,17.854a5.469,5.469,0,0,0,4.013-1.668,5.474,5.474,0,0,0,1.668-4.013A5.467,5.467,0,0,0,16.186,8.16a5.472,5.472,0,0,0-4.013-1.668A5.472,5.472,0,0,0,8.16,8.16a5.467,5.467,0,0,0-1.668,4.013A5.474,5.474,0,0,0,8.16,16.186,5.469,5.469,0,0,0,12.173,17.854Z" fill="#15223d"/>
                                    </svg>                              
                                </button>
                                <span class="text-danger" v-if="storeImageValidations.imgIsRequired">Image field is required</span>
                            </div>
                        </div>
                        <div class="selectCategoryBox">
                            <form>
                                <div class="startedFormBox">
                                    <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                                        <label for="storeCountry">Country<span>*</span></label>
                                        <select name="storeCountry" id="storeCountry" placeholder="Select Country" v-model="selectedStoreCountry">
                                            <option v-for="(country,index) in countriesList" :key="index" :value="country">{{country.name}}</option>
                                        </select>
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="startedFormBox">
                                    <ValidationProvider name="store category" rules="required" v-slot="{ errors }">
                                        <label for="category">Store Category<span>*</span></label>
                                        <select name="category" id="category" placeholder="Select Category" v-model="selectedStoreCategory">
                                            <option v-for="(category,index) in storeCategories" :key="index" :value="category">{{category.name}}</option>
                                        </select>
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="domainTxtBlock">
                                    <div class="startedFormBox" style="margin-bottom: calc(2 * 100vw / 1920);">
                                        <ValidationProvider name="store/domain name" rules="required|max:250" v-slot="{ errors }">
                                            <label for="Domain">Your Store/Domain Name<span>*</span></label>
                                            <input type="text" name="Domain" id="Domain" placeholder="Domain Name" v-model="store.name" @input="domainNameHandle">
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="startedFormBox domain" v-if="store_settings.domain_name">
                                        <p>Your Store URL: <span>{{store_settings.domain_name}}.myyeet.com</span></p>
                                        <p class="fieldInfoMsgbox">You'll be able to add your custom domain from store settings</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="selectThemeBox">
                            <div class="startedFormBox mb-0">
                                <label for="category">Theme<span>*</span></label>
                            </div>
                            <div class="themeSlideBox" v-if="isCategoriesLoading">
                                <div class="themeSelectItem">
                                    <vue-skeleton-loader
                                        type="square"
                                        :width="170"
                                        :height="237"
                                        animation="fade"
                                    />
                                </div>
                                <div class="themeSelectItem">
                                    <vue-skeleton-loader
                                        type="square"
                                        :width="170"
                                        :height="237"
                                        animation="fade"
                                    />
                                </div>
                                <div class="themeSelectItem">
                                    <vue-skeleton-loader
                                        type="square"
                                        :width="170"
                                        :height="237"
                                        animation="fade"
                                    />
                                </div>
                                <div class="themeSelectItem">
                                    <vue-skeleton-loader
                                        type="square"
                                        :width="170"
                                        :height="237"
                                        animation="fade"
                                    />
                                </div>
                            </div>
                            <div class="themeSlideBox" v-else>
                                <div class="themeSelectItem" :class="selectedTheme.id == theme.id ? 'active': ''" v-for="(theme,index) in themesList" :key="index">
                                    <div class="themeImgOnBoarding">
                                        <span class="themeHoverBg" :style="theme.image ? `background-image: url(${base_image_url}${theme.image});` : 'background-image: url(@/assets/images/slideImg.png);'"></span>
                                        <div class="hoverLayerBox" @click="selectTheme(theme)">
                                            <div class="zoomIcon"></div>
                                            <div class="selectIcon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="43.013" height="43.013" viewBox="0 0 43.013 43.013">
                                                    <g id="Group_150" data-name="Group 150" transform="translate(-681 -446)">
                                                        <path id="Path_76" data-name="Path 76" d="M23.506,2A21.506,21.506,0,1,0,45.013,23.506,21.506,21.506,0,0,0,23.506,2Zm0,40.324A18.818,18.818,0,1,1,42.324,23.506,18.818,18.818,0,0,1,23.506,42.324Z" transform="translate(679 444)"/>
                                                        <path id="Path_77" data-name="Path 77" d="M34.976,12.2a1.344,1.344,0,0,0-1.9,0L18.161,27.053,10.1,18.988a1.365,1.365,0,0,0-2,1.855L18.161,30.884,34.976,14.109a1.344,1.344,0,0,0,0-1.909Z" transform="translate(680.972 447.376)"/>
                                                    </g>
                                                </svg>   
                                            </div>
                                            <div class="demoBtn">
                                                <a :href="theme.demo_link.includes('https://') ? theme.demo_link : 'https://'+ theme.demo_link" target="_blank">View Demo Theme</a>
                                            </div>
                                        </div>
                                        <div class="selectThemeTag">
                                            <p>Selected</p>
                                        </div>
                                    </div>
                                    <p class="themeNameTxt">{{theme.name}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="confirmationBox">
                            <input type="checkbox" name="confirmation" id="confirmation" v-model="store_settings.agreement">
                            <span> 
                                By continuing with the store creation process, you are signifying your acceptance of our 
                                <a href="https://yeetcommerce.com/yeetcommerce-privacy-notice/" target="_blank">Privacy Policy</a>
                                and 
                                <a href="https://yeetcommerce.com/yeetcommerce-terms-of-service/" target="_blank">Terms of service</a>.
                            </span>
                        </div>
                        <div class="inboardingSubmitBtn onboard">
                            <button class="backStepBtn" @click.prevent="changeStep('decr')">Back</button>
                            <loaderBtn v-if="loader"/>
                            <button @click.prevent="validate('Four')" v-else>Create Store</button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
            <!-- step 5 -->
            <div class="onboardingRightsideBlock" v-if="activeStep == 5">
                <div class="onboardingContentBox">
                    <div class="onboardingLogo">
                        <a>
                            <img src="@/assets/images/yeet/yeet_logo.png" alt="">
                        </a>
                    </div>
                    <div class="stepsBarBox">
                        <div class="stepsItem"></div>
                        <div class="stepsItem"></div>
                        <div class="stepsItem"></div>
                        <div class="stepsItem"></div>
                        <div class="stepsItem activeStep"></div>
                        <div class="stepsItem"></div>
                    </div>
                    <div class="congratulationsBox">
                        <img src="@/assets/images/dash_animation.gif" alt="">
                        <h3>Please Wait!</h3>
                        <p>Your store is being created. it could take a few minutes.</p> 
                    </div>
                </div>
            </div>
        </div>
        <!-- step 6 -->
        <div class="signup__last-step" v-if="activeStep == 6">
            <div class="container">
                <div class="onboardingRightsideBlock">
                    <div class="onboardingContentBox">
                        <div class="onboardingLogo">
                            <a href="#."><img src="@/assets/images/yeet/yeet_logo.png" alt=""></a>
                        </div>
                        <div class="stepsBarBox">
                            <div class="stepsItem"></div>
                            <div class="stepsItem"></div>
                            <div class="stepsItem"></div>
                            <div class="stepsItem"></div>
                            <div class="stepsItem"></div>
                            <div class="stepsItem activeStep"></div>
                        </div>
                        <div class="congratulationsBox">
                            <div class="congratulationsHeading">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106 106">
                                    <g id="success-standard-line" transform="translate(-2 -2)">
                                        <path id="Path_78" data-name="Path 78" d="M55,2a53,53,0,1,0,53,53A53,53,0,0,0,55,2Zm0,99.375A46.375,46.375,0,1,1,101.375,55,46.375,46.375,0,0,1,55,101.375Z" fill="#097c79"/>
                                        <path id="Path_79" data-name="Path 79" d="M74.875,12.772a3.313,3.313,0,0,0-4.671,0l-36.769,36.6L13.561,29.5a3.364,3.364,0,0,0-4.936,4.571L33.436,58.816l41.439-41.34a3.312,3.312,0,0,0,0-4.7Z" transform="translate(13.25 22.684)" fill="#097c79"/>
                                    </g>                        
                                </svg>
                                <h3>Congratulations!</h3>
                            </div>
                            <p>Your store has been created successfully. Choose the plan that best suits your needs.</p>
                            <div class="inboardingSubmitBtn addProjectBtn">
                                <a :href="`https://`+store_settings.domain" target="_blank">Visit Your Store</a>
                                <router-link :to="`/login?token=${token}`">Manage Your Store</router-link>
                            </div>
                        </div>
                        
                        <hr>

                        <!-- Packages start -->
                        <div class="packagesBox" v-if="packagesLoading">
                            <div class="planChangeBtn">
                                <div class="planChangeToggle">
                                    <vue-skeleton-loader
                                        type="square"
                                        :width="100"
                                        :height="50"
                                        animation="fade"
                                    />
                                </div>
                            </div>
                            <div class="packageContainer" v-for="i in 2" :key="i">
                                <div class="inner">
                                    <vue-skeleton-loader
                                        type="square"
                                        :width="265"
                                        :height="150"
                                        animation="fade"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="packagesBox" v-else-if="this.packagesList.length > 0">
                            <div class="planChangeBtn">
                                <!-- <p>Monthly</p>
                                <div class="planToggleBtn">
                                    <label class="switch">
                                    <input
                                        type="checkbox"
                                        v-model="selectedSubscriptionType"
                                        true-value="annual"
                                        false-value="monthly"
                                    />
                                    <span class="slider"></span>
                                    </label>
                                </div> -->
                                <!-- <p class="tagLine">Annual <span v-if="maxDiscountValue != 0">upto {{ maxDiscountValue }}% Discount</span></p> -->
                                <!-- <p class="tagLine">Annual <span v-if="maxDiscountValue != 0">Save upto {{ maxDiscountValue }}% when you pay annually.</span></p> -->
                                <div class="planChangeToggle">
                                    <p>Monthly</p>
                                    <div class="planToggleBtn">
                                        <label class="switch">
                                        <input
                                            type="checkbox"
                                            v-model="selectedSubscriptionType"
                                            true-value="annual"
                                            false-value="monthly"
                                        />
                                        <span class="slider"></span>
                                        </label>
                                    </div>
                                    <!-- <p class="tagLine">Annual <span v-if="maxDiscountValue != 0">upto {{ maxDiscountValue }}% Discount</span></p> -->
                                    <p class="tagLine">Annual</p>
                                </div>
                                <div class="planDiscountWrap" v-if="maxDiscountValue != 0">
                                    <!-- <p class="tagLine">Annual <span>Save upto {{ maxDiscountValue }}% when you pay annually.</span></p> -->
                                    <p class="tagLine"><span>Save upto {{ maxDiscountValue }}% when you pay annually.</span></p>
                                </div>
                            </div>
                            <div 
                                class="packageContainer" 
                                :class=" createdStore ? createdStore.subscription.package.id == listedPackage.id ? 'current' : '' : ''" 
                                v-for="(listedPackage,index) in packagesList" :key="`package-${index}`"
                            >
                                <div class="inner">
                                    <span class="recommendedBox" v-if="listedPackage.isPrimary">
                                        Recommended
                                    </span>
                                    <!-- <span class="priceBox">
                                        ${{ selectedSubscriptionType == 'annual' ? `${listedPackage.yearly_price}/year` : `${listedPackage.monthly_price}/month` }}
                                    </span> -->
                                    <span class="priceBox">
                                        {{ packagePricing(listedPackage) }}
                                    </span>
                                    <h2 class="title">{{ listedPackage.name }}</h2>
                                    <el-tooltip class="box-item" effect="dark" :content="listedPackage.remarks" placement="top-start" v-if="listedPackage.remarks.length > 40">
                                        <p class="info">
                                            {{ listedPackage.remarks.length > 40 ? listedPackage.remarks.substr(0, 40) + "..." : listedPackage.remarks }}
                                        </p>
                                    </el-tooltip>
                                    <p class="info" v-else>
                                        {{ listedPackage.remarks }}
                                    </p>
                                    <div class="detailsBox">
                                        <a href="https://yeetcommerce.com/yeetcommerce-com-pricing/" target="_blank">View Details</a>
                                        <loaderBtn v-if="subscriptionLoader && selectedPackage && selectedPackage.id == listedPackage.id"/>
                                        <button v-else-if="createdStore && createdStore.subscription.package.id == listedPackage.id"  disabled>Current Plan</button>
                                        <button 
                                            @click="requestUpgradeHandle(listedPackage)" 
                                            :disabled="subscriptionLoader || (listedPackage.package_type == 'custom')"
                                            v-else
                                        >
                                            {{listedPackage.package_type == 'fixed' ? 'Choose Plan' : "Let's Talk" }} 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Packages end -->
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import VueCountdown from '@chenfengyuan/vue-countdown';
import { mapGetters } from 'vuex';
import loaderBtn from "@/components/loaderBtn.vue";
import {MessageBox, Message} from 'element-ui'
import _ from "lodash";

export default {
    metaInfo() {
        return {
            title: 'Start Selling Online for Free | Create Your Own Shop | YeetCommerce.com',
            meta: [
                {
                    name: 'title',
                    content: "Yeetcommerce Onboarding: Easy Start for Your eCommerce Store", 
                },
                {
                    name: 'description',
                    content: "Streamline your Yeetcommerce Onboarding process with our easy guide. Get your eCommerce store up and running quickly and efficiently.", 
                },
            ],
            link: [
                {
                    rel: 'canonical',
                    href: this.fullPath()
                }
            ]
        };
    },
    data:()=>({
        base_image_url: process.env.VUE_APP_STORE_THEME_URL,
        master_base_url: process.env.VUE_APP_MASTER_BASE_URL,

        activeStep: 1,

        selectedTheme:{},
        selectedStoreCategory:[],
        selectedStoreCountry:[],
        allowedCountries:[],
        loader:false,
        selectedCountry:{},
        mobileNoIsValid:true,
        countryIsSelected:true,
        contactFieldTouched:false,
        selectedCountryObj:[],
        countryObjBeforeChange:[],

        store:{
            first_name:'',
            last_name:'',
            name:'',
            email:'',
            verified_email:'',
            password:'',
            passwordType:false,
            otp:'',
            verified_otp:'',
            otp_platform:'whatsapp',
            mobile_no:'',
            theme_repo_link:'',
        },
        store_settings:{
            name:'',
            domain_name:'',
            domain:'domain-name.myyeet.com',
            description:'',
            logo:'',
            logo_src:'',
            agreement:false,
        },
        options:{
            placeholder:"Enter your contact number",
            showDialCode:true,
            maxlength:25,
        },
        dropDownOptions:{
            showSearchBox:true,
            showDialCodeInSelection:false,
            showDialCodeInList:true,
            showFlags:true,
            placeholder:"Select a Country"
        },
        token:'',
        devOtp:'',
        themeValidate:false,
        countriesList:[],
        countDownStart:false,
        isTimeOut:false,
        storeImageValidations:{
            imgIsRequired:false,
            imgInvalidFormat:false,
            isNotImg:false,
        },

        socialLoginChecking:false,
        //google working start
        googleData:null,
        //google working end

        //microsoft working start
        outlookData:null,
        outlookPromptActive:false,
        //microsoft working end

        //created store
        createdStore:null,

        //store owner
        storeOwner:null,

        //subscription packages work
        packagesList:[],
        packagesLoading:false,
        maxDiscountValue:0,
        selectedSubscriptionType: "annual",
        selectedPackage:null,
        currentPackage:null,
        subscriptionLoader:false,

        //country check
        countryCheckLoader:false,
        countryIsUSA:false,
    }),
    components:{
        OtpInput,
        VueCountdown,
        loaderBtn
    },
    computed:{
        ...mapGetters({
           isLoading: 'themes_module/isPending',
           themesList: 'themes_module/themes',
           isCategoriesLoading:'store_categories_module/isPending',
           storeCategories: 'store_categories_module/categories',
        }),
    },
    watch:{
        packagesList:{
            handler:function(value){

                if(value.length > 0){

                    this.packagesLoading = false;

                }

            },deep:true
        },
    },
    methods:{
        numberInputHandle(number,numberObj){

            if(typeof numberObj.country == 'undefined'){

                this.countryIsSelected = false;

            }else if(typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){

                this.countryIsSelected = false;

            }else{

                this.countryIsSelected = true;

            }

            this.selectedCountryObj = numberObj;

            if(this.selectedCountryObj.valid){

                this.mobileNoIsValid = true;

            }else{

                if(this.selectedCountryObj.formatted){

                    this.mobileNoIsValid = false;

                }

            }

        },
        dropDownOpenHandle(){

            this.countryObjBeforeChange = this.selectedCountryObj;

        },
        dropDownCloseHandle(){

            if((this.store.mobile_no).replace(/\s/g,'').length > this.selectedCountry.dialCode.length + 1){

                if((this.countryObjBeforeChange.country != this.selectedCountryObj.country)){

                    this.store.mobile_no = "+" + this.selectedCountry.dialCode;

                }

            }

            if(this.selectedCountryObj.valid){

                this.mobileNoIsValid = true;

            }else{

                this.mobileNoIsValid = false;

            }

        },
        countryChangeHandle(countryObj){

            this.selectedCountry = countryObj;

        },
        validateContactNo(numberObj){

            if(typeof numberObj.valid != 'undefined'){

                this.mobileNoIsValid = numberObj.valid

                if(!this.mobileNoIsValid){

                    this.contactFieldTouched = true

                }else{

                    this.contactFieldTouched = false

                }

            }

        },
        domainNameHandle(){

            let domainName = this.store.name.toLowerCase();

            let storeName = this.store.name;
            domainName = domainName.replace(/\s/g, '-');             //replace space with - 
            domainName = domainName.replace(/(\W)\W*/g, '$1');       //keep one hyphen if consecutive spaces have been added
            domainName = domainName.replace(/[^a-zA-Z0-9-]/, '');    //eliminate special characters (except hyphen)
            domainName = domainName.replace(/-$/, "");              //eliminate final hyphen (if exists)
            
            storeName = storeName.replace(/[^a-zA-Z0-9- ]/g, '');    //eliminate special characters 
            storeName = storeName.replace(/(\s)\s*/g, '$1');         //keep one space if consecutive spaces have been added
            storeName = storeName.replace(/(\W)\W*/g, '$1');         //keep one hyphen if consecutive hyphens have been added
            
            if(domainName.charAt(0) === '-'){

                domainName = domainName.substring(1);

            }

            if(storeName.charAt(0) === '-'){

                storeName = storeName.substring(1);

            }

            this.store.name = storeName;
            this.store_settings.domain_name = domainName;

        },
        selectTheme(theme){

            if(this.selectedTheme == theme){

                this.selectedTheme = {}

            }else{

                this.selectedTheme = theme

            }

        },
        generatePassword(){

            let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+|}{?><:~``}"
            let retVal = "";

            for(var i = 0, n = charset.length; i < 16; ++i){
                retVal += charset.charAt(Math.floor(Math.random() * n));
            }

            this.$refs.passwordInput.value = retVal;
            this.store.password = retVal;

            this.$refs.passwordInput.focus();

        },
        validate(step){

            if(step == 'One'){

                this.$refs.stepOneObserver.validate().then(success => {

                    if(!success){

                        const errors = Object.entries(this.$refs.stepOneObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);
                            
                        this.$refs.stepOneObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    }else{

                        this.sendEmailVerificationOtp();

                    }

                });

                
                // this.$refs.stepOneObserver.validate().then(success => {

                //     if(!success){

                //         const errors = Object.entries(this.$refs.stepOneObserver.errors)
                //         .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);
                            
                //         this.$refs.stepOneObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                //     }else{

                //         if(((this.store.mobile_no).replace(/\s/g,'').length) < 6){
                //             this.mobileNoIsValid = false;
                //         }

                //         if(this.mobileNoIsValid && this.countryIsSelected){
                //             this.sendOtp()
                //         }
                //     }

                // });

            }else if(step == 'Two'){

                this.$refs.stepTwoObserver.validate().then(success => {

                    if(!success){

                        const errors = Object.entries(this.$refs.stepTwoObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                        this.$refs.stepTwoObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    }else{

                        this.verifyEmailVerificationOtp();
                        
                    }

                });

            }else if(step == 'Three'){

                this.$refs.stepThreeObserver.validate().then(success => {

                    if(!success){

                        const errors = Object.entries(this.$refs.stepThreeObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                        this.$refs.stepThreeObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                        if(((this.store.mobile_no).replace(/\s/g,'').length) < 6){

                            this.mobileNoIsValid = false;
                            this.contactFieldTouched = true

                        }

                    }else{

                        if(((this.store.mobile_no).replace(/\s/g,'').length) < 6){

                            this.mobileNoIsValid = false;
                            this.contactFieldTouched = true

                        }

                        if(this.mobileNoIsValid && this.countryIsSelected){

                            this.contactConnectionCheckHandle();

                        }

                    }

                });

            }else if(step == 'Four'){

                this.$refs.onboardingObserver.validate().then(success => {

                    if(!success){

                        const errors = Object.entries(this.$refs.onboardingObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                        this.$refs.onboardingObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                        // if(!this.store_settings.logo){

                        //     this.storeImageValidations.imgIsRequired = true

                        // }

                    }else{

                        if(!this.selectedTheme.id){

                            this.themeValidate = true

                            this.$notify({
                                type: 'error',
                                title: 'Alert',
                                message: "A Theme must be selected to create the store.",
                            });  

                            this.loader = false;

                            return

                        }else{

                            // if(!this.store_settings.logo){

                            //     this.storeImageValidations.imgIsRequired = true

                            //     document.querySelector("#store-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                            // }else{

                                // this.resetStoreImageValidations();

                                this.createStoreHandle();

                            // }

                        }
                        
                    }

                });

            }

        },
        async storeImageHandle(e){

            this.storeImageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.store_settings.logo = file;
                    this.store_settings.logo_src = URL.createObjectURL(file);

                    // this.storeImageValidations.imgIsRequired = false

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6)
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });
                    
                    // if(!this.store_settings.logo){

                    //     this.storeImageValidations.imgIsRequired = true

                    // }

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    // if(!this.store_settings.logo){

                    //     this.storeImageValidations.imgIsRequired = true

                    // }

                }

            }

        },
        resetStoreImageValidations(){

            this.storeImageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

        },
        changeStep(action){

            if(action == "incr"){

                this.activeStep++

            }

            if(action == "decr"){

                if((this.activeStep - 1 == 2) && (this.googleData != null || this.outlookData != null)){

                    this.resetOnboarding();

                    this.activeStep = 1;

                }else{

                    if(this.activeStep - 1 == 1){

                        this.resetOnboarding();

                        this.activeStep = 1;

                    }else{

                        this.resetOnboarding();

                        this.activeStep = 1;

                        // this.activeStep--

                        // if(this.activeStep == 2){

                        //     this.isTimeOut = true;

                        // }

                    }

                }

            }

        },

        //email verification otp methods start
        async sendEmailVerificationOtp(){

            let formData = new FormData();

            formData.append('email',this.store.email);

            this.loader = true;
            try{
                let res = await this.$axios.post('email/verification/otp/send',formData);
                if(res.data.status_code == "1149"){

                    this.loader = false

                    if(this.activeStep != 2){

                        this.activeStep = 2;

                    }else{

                        this.$refs.stepTwoObserver.reset();

                    }

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.isTimeOut = false;

                    this.store.otp = '';

                    this.store.verified_otp = '';

                    if(this.$refs.otpInput){

                        this.$refs.otpInput.clearInput();
                        
                    }

                }
            }catch(error){

                this.loader = false;

                if(error.response){

                    if(error.response.data.error.includes('already')){
                    
                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error,
                        });

                    }else{

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.message,
                        }); 

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }

        },
        handleEmailOtpChange(value){

            this.store.otp = value;

        },
        handleEmailOtpComplete(value){

            this.store.otp = value;

            this.verifyEmailVerificationOtp();

        },
        async verifyEmailVerificationOtp(){

            let formData = new FormData();

            formData.append('email',this.store.email);
            formData.append('otp',this.store.otp);

            this.loader = true;
            try{
                let res = await this.$axios.post('/email/verification/otp/verify',formData);
                if(res.data.status_code == "1146"){

                    this.loader = false;

                    this.activeStep = 3;

                    this.store.verified_otp = _.cloneDeep(this.store.otp);
                    this.store.verified_email = _.cloneDeep(this.store.email);

                    this.$refs.otpInput.clearInput();
                    
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                }
            }catch(error){

                this.loader = false

                if(error.response){

                    if(error.response.data.error.otp){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: "Entered OTP is invalid",
                        });

                    }else if(error.response.data.error.includes('OTP')){
                    
                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error,
                        });

                    }else{

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.message,
                        });

                    }

                }else{
                    
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }
                
            }

        },
        //email verification otp methods end

        //register with google methods start
        onboardWithGoogle(){

            let initgoogle = google.accounts.oauth2.initTokenClient({
                client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID_FINAL,
                callback: this.handleCredentialResponse,
                auto_select: false,
                scope: "profile email"
            });

            initgoogle.requestAccessToken();

        },
        async handleCredentialResponse(response){
            
            await fetch("https://www.googleapis.com/userinfo/v2/me",{
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${response.access_token}`
                },
            }).then((userInfo) => {

                return userInfo.json();

            }).then((data) => {

                if(this.activeStep == 1){

                    this.googleData = data;

                    this.socialLoginCheckHandle('google');

                }

            });

        },
        async socialLoginCheckHandle(platform){

            let formData = new FormData();

            if(platform == 'google'){

                formData.append('email',this.googleData.email);
                formData.append('social_id',this.googleData.id);

            }else if(platform == 'outlook'){

                formData.append('email',this.outlookData.username);
                formData.append('social_id',this.outlookData.localAccountId);

            }

            this.socialLoginChecking = true;
            this.loader = true;
            try{
                let res = await this.$axios.post('/social-login/check',formData);
                if(res.data.status_code == "2020"){

                    if(platform == "google"){

                        this.store.first_name = this.googleData.given_name;
                        this.store.last_name = this.googleData.family_name;
                        this.store.email = this.googleData.email;
                        this.store.verified_email = this.googleData.email;

                    }else if(platform == "outlook"){
                        
                        let splittedName = this.outlookData.name.split(" ");

                        this.store.first_name = splittedName[0];
                        this.store.last_name = splittedName[1];
                        this.store.email = this.outlookData.username;
                        this.store.verified_email = this.outlookData.username;
                        
                    }

                    this.activeStep = 3;

                    this.socialLoginChecking = false;
                    this.loader = false;

                }
            }catch(error){

                this.socialLoginChecking = false;
                this.loader = false;

                if(error.response){

                    if(error.response.data.error.includes('provided email address')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }


                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }

        },
        //register with google methods end

        //register with microsoft methods start
        onboardWithOutlookInitiate(){

            const redurectUrl = window.location.origin + '/onboarding'

            const msalConfig = {
                auth: {
                    clientId: process.env.VUE_APP_OUTLOOK_CLIENT_ID,
                    authority: 'https://login.microsoftonline.com/common',
                    redirectUri: redurectUrl,
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
            };

            this.onboardWithOutlookProceed(msalConfig);

        },
        async onboardWithOutlookProceed(msalConfig){
            
            if(!this.outlookPromptActive){

                this.outlookPromptActive = true;

                const loginRequest = {
                    scopes: ['email','openid', 'profile', 'User.Read'],
                    prompt: 'select_account',
                };

                const myMSALObj = new msal.PublicClientApplication(msalConfig);

                myMSALObj.loginPopup(loginRequest).then(response => {

                    if(this.activeStep == 1){

                        this.outlookData = response.account;

                        this.outlookPromptActive = false;

                        this.socialLoginCheckHandle('outlook');

                    }

                }).catch(error => {

                    this.outlookPromptActive = false;

                });

            }

        },
        //register with microsoft methods end


        //abandoned methods start
        async emailVerificationLink(){

            let formData = new FormData();

            formData.append('email',this.store.email);
            formData.append('first_name',this.store.first_name);
            formData.append('last_name',this.store.last_name);
            formData.append('mobile_no',this.store.mobile_no.replace(/\s/g,''));

            this.loader = true;
            try{
                let res = await this.$axios.post('/email/verification/link/send',formData);
                if(res.data.status_code == "1145"){

                    if(this.activeStep != 4){

                        this.activeStep = 4;

                    }

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    })

                    this.loader = false;

                }

            }catch(error){

                this.loader = false;

                if(error.response){

                    if(error.response.data.error.includes('email address')){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("been verified")){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error,
                        });

                    }else{

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: "Something went wrong. Please try later.",
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }
                
            }

            this.loader = false;
            
        },
        async sendOtp(){

            if(this.store.mobile_no.length == 0){

                this.$notify({
                    type: 'warning',
                    title: 'Contact No.',
                    message: "contact no. is required",
                })

                return

            }

            this.loader = true;
            let formData = new FormData();

            formData.append('contact_no',this.store.mobile_no.replace(/\s/g,''))
            formData.append('receive_via',this.store.otp_platform);

            try{
                let res = await this.$axios.post('/contact/verification/otp/send',formData);
                if(res.data.status_code == "1147"){

                    if(this.activeStep != 2){

                        this.activeStep = 2;

                    }

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.loader = false;

                    this.isTimeOut = false;
                    
                    this.store.verified_otp = '';

                    if(res.data.otp){

                        this.devOtp = res.data.otp;

                    }

                }

            }catch(error){

                this.loader = false;
                if(error.response){

                    if(error.response.data.error.includes('email address')){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes('already registered')){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("been verified")){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error,
                        });

                    }else if (error.response.data.error.includes("not valid")){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error,
                        });

                    }else if (error.response.data.error.includes("messaging service")){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error,
                        });

                    }else{

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.message,
                        }); 

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }
            this.loader = false;
        },
        handleOtpChange(value){

            this.store.otp = value;

        },
        handleOtpComplete(value){

            this.store.otp = value;

            this.verifyOtp();

        },
        async verifyOtp(){

            this.loader = true;
            let formData = new FormData();

            formData.append('otp',this.store.otp);
            formData.append('contact_no',this.store.mobile_no.replace(/\s/g,''));

            try{

                let res = await this.$axios.post('/contact/verification/otp/verify',formData);
                if(res.data.status_code == "1148"){

                    this.activeStep = 3;

                    this.store.verified_otp = this.store.otp;
                    this.devOtp = '';

                    this.$refs.otpInput.clearInput();
                    
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    })  

                    this.loader = false;

                }

            }catch(error){ 

                this.loader = false;

                if(error.response){

                    if(error.response.data.error.otp){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: "Entered OTP is invalid",
                        })

                    }else if(error.response.data.error.email){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error.email[0],
                        }) 

                    }else if(error.response.data.error.includes('expired')){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: "OTP is expired. Please generate a new one from step 1.",
                        }) 

                    }else if(error.response.data.error.includes('been verified')){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error,
                        });

                    }else{

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.message,
                        }) 

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }
            }

            this.loader = false;

        },
        //abandoned methods end

        //contact no connection check method start
        async contactConnectionCheckHandle(){

            let formData = new FormData();

            formData.append('contact_no',this.store.mobile_no.replace(/\s/g,''));

            this.loader = true;
            try{
                let res = await this.$axios.post('/contact/check',formData);
                if(res.data.status_code == "2020"){

                    if(this.activeStep != 4){

                        this.activeStep = 4;

                    }

                    this.loader = false;

                }
            }catch(error){

                this.loader = false

                if(error.response){

                    if(error.response.data.error.includes('already')){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error,
                        });

                    }else{

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }

        },
        //contact no connection check method end

        //store creation start
        async createStoreHandle(){

            if(!this.store_settings.agreement){

                this.$notify({
                    type: 'info',
                    title: 'Acknowlegement Required',
                    message: "You are required to acknowledge the privacy policy and terms of service to create your store.",
                });

                return

            }

            this.loader = true;
            try{
                let res = await this.$axios.get(this.master_base_url+'/subdomain/availability/'+this.store_settings.domain_name);
                if(res.data.status_code == "1002"){

                    if(res.data.data.available){

                        this.activeStep = 5;

                        this.store_settings.domain = res.data.data.domain;

                        let form = new FormData();

                        form.append('first_name',this.store.first_name);
                        form.append('last_name',this.store.last_name);
                        form.append('mobile_no',this.store.mobile_no.replace(/\s/g,''));
                        form.append('email',this.store.verified_email);
                        form.append('password',this.store.password);
                        form.append('store_category_id',this.selectedStoreCategory.id);
                        
                        if(this.store_settings.logo){

                            form.append('logo',this.store_settings.logo);

                        }

                        form.append('selected_theme_id',this.selectedTheme.id);
                        form.append("domain_name",this.store_settings.domain);
                        form.append("received_domain",this.store_settings.domain_name);
                        form.append("name",this.store.name);
                        form.append('country_id',this.selectedStoreCountry.id);

                        if(this.googleData != null || this.outlookData != null){

                            form.append('signup_mode','social')

                            if(this.googleData != null){

                                form.append('social_id',this.googleData.id);
                                form.append('social_platform','google');

                            }else if(this.outlookData != null){

                                form.append('social_id',this.outlookData.localAccountId);
                                form.append('social_platform','microsoft');

                            }

                        }else{

                            form.append('signup_mode','normal');
                            form.append('verification_otp',this.store.verified_otp);

                        }

                        let resCreate = await this.$axios.post('/createStore',form);
                        if(resCreate.data.status_code == "1115"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: resCreate.data.message,
                            })

                            this.loader = false;

                            this.token = resCreate.data.token;
                            this.activeStep = 6;

                            this.createdStore = resCreate.data.store;

                            this.storeOwner = resCreate.data.store_admin;

                        }

                    }else{

                        this.$notify({
                            type: 'warning',
                            title: 'The Subdomain Name is Unavailable.',
                            message: "The requested subdomain name is not available. Kindly change your domain name.",
                        });

                    }

                }else{

                    this.$notify({
                        type: 'warning',
                        title: 'The Subdomain Name is Unavailable.',
                        message: "The requested subdomain name is not available. Kindly change your domain name.",
                    });

                }

            }catch(error){

                if(error.response){

                    if(error.response.data.error.email){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error.email[0],
                        })

                    }else if(error.response.data.error.name){

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: error.response.data.error.name[0],
                        })

                    }else{

                        this.$notify({
                            type: 'error',
                            title: 'Alert',
                            message: "Something went wrong. Please try later.",
                        });

                    }

                    this.activeStep = 4;

                }else{

                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        message: error.message,
                    })

                    this.activeStep = 4;

                }
                
            }
            
            this.loader = false;
        },
        //store creation start

        fullPath(){

            return window.location.origin + this.$route.fullPath;

        },
        resetOnboarding(){

            this.store = {
                first_name:'',
                last_name:'',
                name:'',
                email:'',
                verified_email:'',
                password:'',
                passwordType:false,
                otp:'',
                verified_otp:'',
                otp_platform:'whatsapp',
                mobile_no:'',
                theme_repo_link:'',
            }

            this.store_settings = {
                name:'',
                domain_name:'',
                domain:'domain-name.meriwebshop.com',
                description:'',
                logo:'',
                logo_src:'',
            }

            this.selectedTheme = {};
            this.selectedStoreCategory = [];
            this.selectedStoreCountry = [];
            this.allowedCountries = [];

            this.googleData = null;
            this.outlookData = null;

        },

        //subscription package methods start
        async requestUpgradeHandle(listedPackage){
            
            MessageBox.confirm(
                'Are you sure you want to upgrade your plan?',
                'Warning',
                {
                    type: 'warning',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                }
            ).then(async ()=>{

                if(listedPackage.package_type == 'custom'){

                    this.$notify({
                        type: 'info',
                        title: 'Cannot Proceed',
                        message: "Custom package cannot be subscribed.",
                    });

                    return 

                }

                this.$notify({
                    type: 'info',
                    title: 'Processing',
                    message: "Your upgrade request is underway. Your patience is valued.",
                });

                this.selectedPackage = listedPackage;

                let formData = new FormData();

                formData.append("payment_type", "stripe");
                formData.append("package_id", listedPackage.id);
                formData.append("subscription_type", this.selectedSubscriptionType);
                formData.append("store_admin_id",this.storeOwner.id);

                this.subscriptionLoader = true;
                
                try{
                    let res = await this.$axios.post('/onboarding/subscription/change/request',formData,{
                        headers:{
                            'X-Store-Id':this.createdStore.id
                        }
                    });
                    if(res.data.status_code == "1171"){

                        let stripeFormData = new FormData(); 

                        stripeFormData.append("package_id", this.selectedPackage.id);
                        stripeFormData.append("subscription_type", this.selectedSubscriptionType);
                        stripeFormData.append("subscription_request_id",res.data.request.id);
                        stripeFormData.append("store_admin_id",this.storeOwner.id);

                        try{
                            
                            let stripeRes = await this.$axios.post('onboarding/subscription/stripe/payment-link',stripeFormData,{
                                headers:{
                                    'X-Store-Id':this.createdStore.id
                                }
                            });
                            if(stripeRes.data.status_code == "1340"){

                                this.selectedPackage = null;

                                this.subscriptionLoader = false;

                                window.location.replace(stripeRes.data.url);

                            }

                        }catch(error){

                            if(error.response){

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.message,
                                });

                            }

                        }

                    }
                }catch(error){
                    // this.subscriptionLoader = false

                    this.selectedPackage = null;

                    if(error.response){

                        if(error.response.data.error.includes("not enough for your current resources")){

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.response.data.error,
                            });

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }

            }).catch(()=>{})

        },
        packagePricing(listedPackage){

            if(this.selectedSubscriptionType && this.selectedSubscriptionType == 'annual'){

                let annualPerMonthPrice = 0.00;

                if(listedPackage.annual_discount == 0){

                    annualPerMonthPrice = listedPackage.yearly_price

                }else{

                    annualPerMonthPrice = (listedPackage.yearly_price / 12).toFixed(2)

                }

                return `$${annualPerMonthPrice} / month`

            }else{

                return `$${listedPackage.monthly_price} / month`

            }

        },
        //subscription package methods end

        //environment check
        isDev(){

            if(process.env.VUE_APP_ENV == 'dev'){

                return true;

            }else{

                return false;

            }

        }

    },
    mounted(){

        window.addEventListener('beforeunload', (event) => {

            if(this.activeStep != 6){

                event.returnValue = "Are you sure you want to leave? entered information will be lost.";
            
            }

        });

    },
    async beforeMount(){

        this.countryCheckLoader = true
        
        this.packagesLoading = true

        if(this.themesList.length == 0){

            this.$store.dispatch('themes_module/fetchThemesRequest');

        }

        if(this.storeCategories.length == 0){

            this.$store.dispatch('store_categories_module/fetchStoreCategoriesRequest');

        }

        if(this.$route.query.email){

            this.store.email = this.$route.query.email;

            this.$router.replace({'query': null});

        }

        let res = await this.$axios.get('/countries/all');
        if(res.data.status_code == "1010"){

            this.countriesList = res.data.countries;

            this.countriesList.forEach((country)=>{

                this.allowedCountries.push(country.iso2);

            });

        }

        try{

            let countryRes = await this.$axios.get('/my-country');
            if(countryRes.data.status_code == "1300"){
                
                this.countryCheckLoader = false;

                if(countryRes.data.country == 'United States' || countryRes.data.country == 'Canada'){

                    this.countryIsUSA = true;

                }

            }

        }catch(error){
            
            this.countryCheckLoader = false;

        }

        let packagesRes = await this.$axios.get('/getPackages?active=1');
        if(packagesRes.data.status_code == "1210"){

            this.packagesList = packagesRes.data.packages;

            // this.packagesList = this.packagesList.sort((a,b) => a.monthly_price - b.monthly_price);

            this.packagesList = this.packagesList.sort((a, b) => {
                if (a.package_type === "custom" && b.package_type !== "custom") return 1;
                if (a.package_type !== "custom" && b.package_type === "custom") return -1;
                return a.monthly_price - b.monthly_price;
            });

            this.maxDiscountValue = Math.max(...this.packagesList.map(pkg => pkg.annual_discount));

            this.packagesLoading = false;

        }

    },
    beforeDestroy(){

        window.removeEventListener('beforeunload', (event) => {

            if(this.activeStep != 6){

                event.returnValue = "Are you sure you want to leave? entered information will be lost.";
            
            }

        });

    }
}
</script>

<style>
    .onBoardingAsideTxtBox{
        display: flex;
        height: 100%;
        flex-direction: column;
        padding-top: 64px;
        flex: 1;
    }
    .onBoardingCoverImage{
        width: 100%;
        position: relative;
        background-color: #fff;
        flex: 1;
        display: flex;
        left:64px;
        /* margin-bottom: 100px; */
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    }
    .onboardingAsideCoverImg{
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    .boardingStatImg1,.boardingStatImg2,.boardingStatImg3{
        position: absolute;
        animation: zoom-in-zoom-out 1s ease infinite;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        border-radius: 20px;
        overflow: hidden;
    }
    .boardingStatImg1 img,.boardingStatImg2 img,.boardingStatImg3 img{
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    .boardingStatImg1{
        width: 288px;
        height:97px;
        top: -63px;
        right: -69px;
    }
    .boardingStatImg2{
        right: -88px;
        bottom: 85px;
        width:198px;
        height: 172px;
    }
    .boardingStatImg3{
        width: 200px;
        height: 180px;
        bottom: -77px;
        left: -37px;
    }
    .whatsappIcon{
        position: fixed;
        bottom: 1%;
        left: 1%;
        width: 60px;
        height: 60px;
        z-index: 99;
    }
    .whatsappIcon img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    @media screen and (max-width: 1600px) and (min-width: 1537px) {
        .onboardingAsideTxtBox{
            margin-bottom: 120px;
        }
        .onBoardingCoverImage{
            margin-bottom: 0;
        }
        .boardingStatImg1 {
            width: 245px;
            height: 81px;
            top: -55px;
            right: -52px;
        }
        .boardingStatImg2 {
            right: -80px;
            bottom: 56px;
            width: 180px;
            height: 160px;
        }
        .boardingStatImg3 {
            width: 170px;
            height: 145px;
            bottom: -60px;
            left: -37px;
        }
    }
    @media screen and (max-width: 1536px) and (min-width: 1367px) {
        .onboardingAsideTxtBox{
            margin-bottom: 140px;
        }
        .onBoardingCoverImage{
            margin-bottom: 0;
        }
        .boardingStatImg1 {
            width: 245px;
            height: 81px;
            top: -60px;
            right: -52px;
        }
        .boardingStatImg2 {
            right: -80px;
            bottom: 50px;
            width: 180px;
            height: 160px;
        }
        .boardingStatImg3 {
            width: 170px;
            height: 145px;
            bottom: -70px;
            left: -37px;
        }
    } 
    @media screen and (max-width: 1366px) and (min-width: 1025px) {
        .onboardingAsideTxtBox {
            margin-bottom: 145px;
        }
        .onBoardingCoverImage{
            margin-bottom: 0;
        }
        .boardingStatImg1 {
            width: 225px;
            height: 70px;
            top: -49px;
            right: -52px;
        }
        .boardingStatImg2 {
            right: -74px;
            bottom: 49px;
            width: 158px;
            height: 135px;
        }
        .boardingStatImg3 {
            width: 141px;
            height: 121px;
            bottom: -35px;
            left: -37px;
        }
    }
    @media screen and (max-height: 1010px) and (min-height: 910px){
        .onboardingAsideTxtBox {
            margin-bottom: 95px;
        }
        .onBoardingCoverImage {
            height: 540px;
        }
        .boardingStatImg1 {
            width: 245px;
            height: 81px;
            top: -55px;
            right: -52px;
        }
        .boardingStatImg2 {
            right: -80px;
            bottom: 56px;
            width: 180px;
            height: 160px;
        }
        .boardingStatImg3 {
            width: 170px;
            height: 145px;
            bottom: -60px;
            left: -37px;
        }

        /* .onboardingAsideTxtBox{
            margin-bottom: 60px;
        }
        .onboardingAsideCoverImg{
            width: 923px!important;
            height: 570px!important;
            object-fit: fill;
        } */
    }




    .otp-input {
        width: 50px;
        height: 50px;
        padding: 5px;
        margin: 0 10px;
        font-size: 20px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        text-align: center;
    }
    .error {
        border: 1px solid red !important;
    }
    .otp-input::-webkit-inner-spin-button,
    .otp-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .el-radio-group label{
        height: 100%;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .el-radio-group .el-radio-button .el-radio-button__inner{
        margin-left: 0px !important;
        transition: all 0.3s ease-in-out;
        display: inline-block;
        padding: 10px 33px;
    }
    .el-radio-group .el-radio-button .el-radio-button__inner:hover{
        margin-left: 0px !important;
        background-color:#fd4e5d;
        color:#fff;
        transition: all 0.3s ease-in-out;
        display: inline-block;
    }
    .el-radio-group .el-radio-button.is-active .el-radio-button__inner{
        background-color: #15223D;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 33px;
        text-transform: uppercase;
        margin-left: 0px !important;
        border-color: #15223D;
        transition: all 0.3s ease-in-out;
        display: inline-block;
    }
    .startedFormBox.passwordField{
        position: relative;
    }
    .startedFormBox.passwordField .PasswordEyeBtn{
        background-color: transparent;
        padding: 0px;
        margin: 0px;
        border: none;
        width: 17px;
        height: 17px;
        position: absolute;
        top: 50px;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .startedFormBox.passwordField .genPasswordBtn{
        top: 18px;
    }
    

</style>